import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

import "./css/datenschutz.scss"

export default function Impressum() {
  return (
    <Layout>
      <div className="impressum">
        <h1>Impressum</h1>
        <h2 id="m46">Diensteanbieter</h2>
        <p> Mark Brockhoff</p>
        <p> Steinsfeldstr. 61</p>
        <p> 74626 Bretzfeld</p>
        <h2 id="m56">Kontaktmöglichkeiten</h2>
        <p>
          <strong>E-Mail-Adresse</strong>:{" "}
          <a href="mailto:band@octatonics.de">band@octatonics.de</a>
        </p>
        <p>
          <strong>Kontaktformular</strong>:{" "}
          <a href="mailto:band@octatonics.de">band@octatonics.de</a>
        </p>
        <ul className="m-elements"></ul>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/?l=de"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>

        <Button className="button" variant="danger">
          <Link to="/">Zur Startseite</Link>
        </Button>
      </div>
    </Layout>
  )
}
